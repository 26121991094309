import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import classes from './TopNav.module.css'


export default function TopNav(props) {



    return (

        <>
        <div className={classes['top-nav']} >

            <div className={classes['top-nav__left']}>

                <div className={classes['top-nav__logo']}>
                    <Link to='/'><h1>ACCORDINIZER</h1></Link>
                     
                </div>
            </div>

            <div className={classes['top-nav__right']}>

                <div className={classes['top-nav__home']}>
                    <NavLink activeClassName={classes['top-nav--selected']} exact to='/'>Home</NavLink>
                </div>

                <div className={classes['top-nav__contact']}>
                    <NavLink activeClassName={classes['top-nav--selected']} to='/contact'>Contact</NavLink>
                </div>

                <div className={classes['top-nav__login']}>
                    <NavLink activeClassName={classes['top-nav--selected']} to='/login'>Login</NavLink>
                </div>
            </div>
        </div>
        </>
    )
}
