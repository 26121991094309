import React, { useState } from 'react'
import classes from './PreSideNav.module.css'
import { NavLink } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom'

export default function PreSideNav() {
    const [displayMode, setDisplayMode] = useState(false)

    let toggle = (
        <>
        <IconButton>
            <MenuIcon className={classes['hamburger-icon']} onClick={() => setDisplayMode(!displayMode)}/>
        </IconButton>
        <span className={classes['logo']}><Link to='/'><strong>ACCORDINIZER</strong></Link></span>
        </>
        )
    let closed = <div className={classes['spacer']}><div className={classes['side-nav__closed']}>{toggle}</div></div>


    let open = (
        <div className={classes['side-nav__open']}>
            <div className={classes['side-nav__toggle']}>{toggle}</div>
            <div className={classes['side-nav__content']}>

                <div className={classes['side-nav__content__home']}>
                    <NavLink activeClassName={classes['side-nav__content--selected']}  exact to = "/">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home" class="svg-inline--fa fa-home fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg>
                    <span>Home</span></NavLink>
                </div>

                <div className={classes['side-nav__content__contact']}>
                    <NavLink activeClassName={classes['side-nav__content--selected']} to = "/contact">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                    <span>Contact</span></NavLink>               
                </div>
                
                <div className={classes['side-nav__content__login']}>
                    <NavLink activeClassName={classes['side-nav__content--selected']} to = "/login">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                    <span>Login</span></NavLink>
                </div>
            </div>
        </div>
    )

    
    
       
        


    
    return (
        <div className={classes['initial']}>
            {displayMode ? open : closed}
        </div>
    )
}
