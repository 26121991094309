import React from 'react';
import { Switch,Route } from "react-router-dom";
import AccordFile from './components/AccordFile/AccordFile';
import FileSelection from './components/FileSelection/FileSelection';
import { Signup } from './components/Signup/Signup';
import { AuthProvider } from './contexts/AuthContext';
import { Login } from './components/Login/Login'
import PrivateRoute from './components/Route/PrivateRoute'
import PublicRoute from './components/Route/PublicRoute'
import {ForgotPassword} from './components/ForgotPassword/ForgotPassword'
import { UpdateProfile } from './components/UpdateProfile/UpdateProfile';
import { FileProvider } from './contexts/FileContext';
import HomePage from './components/HomePage/HomePage';
import Contact from './components/Contact/Contact';

function App() {

  return (

  <AuthProvider>
  <FileProvider>


    <Switch>
    <PrivateRoute path="/FileSelection/:id" exact component={AccordFile} />
    <PublicRoute path="/signup" exact component={Signup} />
    <PublicRoute path="/login" exact component={Login} />
    <PublicRoute path="/forgot-password" exact component={ForgotPassword} />
    <PublicRoute path="/" exact component={HomePage}/>
    <Route path="/contact" exact component={Contact}/>
    
    <PrivateRoute path="/FileSelection" exact component={FileSelection} />
    <PrivateRoute path="/update-profile" exact component={UpdateProfile} />

    </Switch>
  </FileProvider>
  </AuthProvider>
  );
}

export default App;
