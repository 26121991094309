import React, { useState } from 'react';
import classes from './Login.module.css'
import { useAuth } from '../../contexts/AuthContext'
import {Link, useHistory } from 'react-router-dom'
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import Back from '../Back/Back';
import TopNav from '../TopNav/TopNav';
import PreSideNav from '../SideNav/PreSideNav'
import useViewport from '../../useViewport'
export const Login = (props) => {


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { width } = useViewport();
    const breakpoint = 1000;

    async function handleSubmit(e) {
        e.preventDefault()

        try{
            setError('')
            setLoading(true)
            await login(email, password)
            history.push('/')
        } catch {
            setError('Failed to sign in')
        }
        setLoading(false)
    }

    return(
        <>
    <div className={classes['container']}>
        {width < breakpoint ? <PreSideNav/> : <TopNav/>}
        <div className={classes['login']}>

            <div className={classes['login__title']}>
                <h1>Accordinizer</h1>
            </div>
            <div className={error && `${classes['error']}`}>{error}</div>
            <form 
            className={classes['login__form']}
            onSubmit={handleSubmit}>
                <div className={classes['login__email']}>
                    <EmailIcon/>
                    <label> Email</label>
                    <input type='email' 
                    placeholder='Email'
                    value={email} 
                    onChange={(e) =>{ setEmail(e.target.value)}}/>
                </div>

                <div className={classes['login__password']}>

                    <div>
                        <LockIcon/>
                        <label>Password</label>
                    </div>
                    <input 
                    variant="filled"
                    label="password"
                    type="password" 
                    value={password} 
                    onChange={(e) =>{ setPassword(e.target.value)}}
                    placeholder='Password'
                    />
                </div>   

                <div className={classes['login__submit']}>
                    <button type='submit' disabled={loading}>Log In</button>
                </div>
            </form>

                <div className={classes['login__signup']}>
                    Need an account? <Link to ='/signup'>Sign up</Link>
                </div>
                
                <div className={classes['login__forgot']}>
                    <Link to = "/forgot-password">Forgot Password?</Link>
                </div>
        </div>
    </div>
    </>
    )
}
