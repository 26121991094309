import React, { useState } from 'react'
import classes from './SideNav.module.css'
import { useAuth } from '../../contexts/AuthContext'
import { NavLink, useHistory } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import useViewport from '../../useViewport'


export default function SideNav(props) {
const [error, setError] = useState('')
const { logout } = useAuth()
const [displayMode, setDisplayMode] = useState(false)
const history = useHistory()
const { width } = useViewport();
const breakpoint = 1000;



        async function handleLogout(){
            setError('')

            try{
                await logout('')
                history.push('/login')
            }
            catch{
                setError('failed to log out')
            }
        }


        let toggle = (
            <div className={classes['hamburger']}> 
                <IconButton>
                    <MenuIcon className={classes['hamburger-icon']} onClick={() => setDisplayMode(!displayMode)}/>
                </IconButton>
            </div>

        )

        let closed = <div className={classes['']}>{toggle}</div>
        
        let open = (
            <>        
            <div className={classes['hamburger']}>
                {width < breakpoint ? toggle : null}
            </div>
            <div className={classes['side-nav']}>                
                <div className={classes['side-nav__file']}>
                    <NavLink activeClassName={classes['side-nav--selected']}to ='/FileSelection' exact>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-alt" class="svg-inline--fa fa-file-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path></svg> 
                    Files</NavLink>
                </div>

                <div className={classes['side-nav__user']}>
                    <NavLink activeClassName={classes['side-nav--selected']} to = "/update-profile" exact>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg> 
                    Account</NavLink>
                </div>
                
                <div className={classes['side-nav__envelope']}>
                    <NavLink activeClassName={classes['side-nav--selected']}to='/contact' exact>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                    Contact</NavLink>
                </div>


                <div className={classes['side-nav__logout']}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" class="svg-inline--fa fa-sign-out-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
                    <button className={classes['side-nav__logout__button']} onClick={handleLogout}>Log Out</button>
                </div>
            </div>
        </>
        )
    

        let output = closed;
        if(width > breakpoint){
            output = open
        }

        if(displayMode){
            output = open;
        }
        
return (
   
    <div>
        {output}
    </div>
    )
}
