import React from 'react'
import classes from './HomePage.module.css'
import DemoAccordion from '../DemoAccordion/DemoAccordFile'
import TopNav from '../TopNav/TopNav'
import PreSideNav from '../SideNav/PreSideNav'
import useViewport from '../../useViewport'

export default function HomePage() {
    const { width } = useViewport();
    const breakpoint = 1000;

    return (
        <div>
            <div className={classes['presentation']}>
                <div className={classes['navbar']}>
                    {width < breakpoint ? <PreSideNav/> : <TopNav/>}
                </div>

                <div className ={classes['top']}>

                    <div className={classes['video']}>
                        <iframe width="1000px" height="562.5px" src="https://www.youtube.com/embed/EXaGpo0-eKE?list=PLRR3aHQ7vLdCacR4GfjxQYW2mBVYi3R8V" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>

                    <div className={classes['reasons']}>
                        <div className={classes['reasons__item']}>
                            
                            <p className={classes['reasons__item__title']}><span>Reduce Clutter</span></p>
                            <p className={classes['reason__item__description']}>See what you want and not a second sooner.</p>
                        </div>
                        <div className={classes['reasons__item']}>
                            
                            <p className={classes['reasons__item__title']}><span>Cloud-Based</span></p>
                            <p className={classes['reason__item__description']}>Access your information, on any device, at any time.</p>
                        </div>

                        <div className={classes['reasons__item']}>
                            
                            <p className={classes['reasons__item__title']}><span>Rich Text Editor</span></p>
                            <p className={classes['reason__item__description']}>All the features you've come to expect from a modern text editor.</p>
                        </div>
                        <div className={classes['reasons__item']}>
                            <p className={classes['reasons__item__title']}><span>Search</span></p>
                            <p className={classes['reason__item__description']}>Find what you need, when you need it, in real time.</p>
                        </div>
                    </div>
                    <div className={classes['shade']}/>
                </div>
                <div className={classes['demo']}>
                    <h1>Try It for yourself!</h1>
                    <DemoAccordion/>
                </div>
            </div>
        </div>
    )
}
