import React, { useState } from 'react';
import DemoAccordEntry from '../DemoAccordion/DemoAccordEntry'
import classes from '../DemoAccordion/DemoAccordion.module.css'
import AddBoxIcon from '@material-ui/icons/AddBox';


const AccordionEntries = (props) =>{

    const [accordFetch, setAccordFetch] = useState([{title: 'Edit me!', info: 'Add a description'}])
        let isEmpty = ''
        if(accordFetch.length <= 1){
            isEmpty = (<div className={classes['isEmpty']}>Add Your First Entry!</div>)
        }
    const addEntryHandler = () => {
        const information = {title: 'untitled', info: 'Add a description!'}
        const accordFetchCopy = [...accordFetch];
        accordFetchCopy.push(information)
        setAccordFetch(accordFetchCopy)
    }


    const removeEntryHandler = (index) => {

        const accordFetchCopy = [...accordFetch]
        accordFetchCopy.splice(index, 1)
         setAccordFetch(accordFetchCopy)
    }

    const titleHandler = (index, event) =>{
        const accordListT = [...accordFetch]
        accordListT[index].title = event.target.value;
         setAccordFetch(accordListT)
    }
    const infoHandler = (index, content) => {
        const accordListT = [...accordFetch]
        accordListT[index].info = content;
        setAccordFetch(accordListT)
    }



    
        const accordFetchArray =  accordFetch.map((value, index, arr) =>{
            // console.log(arr)
            return (
                <DemoAccordEntry 
                title ={value.title}
                info={value.info}
                titleHandler={(event) => titleHandler(index, event)}
                infoHandler={infoHandler}
                remove = { removeEntryHandler}
                index={index}
                eventKey={index}/>
            )
        })


        
        
        return(
            <div className={classes['background']}>
                <div className={classes.container}>
                    {accordFetchArray}
                    <button className={classes['add-entry']} onClick={ addEntryHandler}>
                    <AddBoxIcon/>
                    <span className={classes['add-entry__text']}>
                    Add entry
                    </span>
                    </button>
                    
                    {isEmpty}
                    <div className={classes['floating-button']} onClick={addEntryHandler}>
                    <img className={classes['floating-button__icon']} src='/Icons/plus-solid.svg'/>
                    </div>
                </div> 
            </div>
        )
}

export default AccordionEntries;