import React, { useState } from 'react'
import {useAuth} from '../../contexts/AuthContext'
import { useFiles } from '../../contexts/FileContext'
import FileName from '../FileName/FileName'
import classes from './FileSelection.module.css'
import AddBoxIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import SideNav from '../SideNav/SideNav'

const FileSelection = (props) => {
    
    const { idToken } = useAuth()
    const { files, addFileHandler, renameFileHandler, deleteFileHandler } = useFiles()
    const [searchTerm, setSearchTerm] = useState('')

    let fileArray = '';
    if(files){
        fileArray = files.filter((value) =>{
            if(searchTerm == ''){
                return value
            } else if (value[1].fileName.toLowerCase().includes(searchTerm.toLowerCase())){
                return value
            }
        }).map( (value, index, arr) =>{
            return(
                <div >
                <FileName 
                className={classes['file-list__items']}
                id ={value[0]} 
                fileName={value[1].fileName}
                rename={event => renameFileHandler(index, event)}
                delete={deleteFileHandler} token={idToken}
                fullFile = {value}
                />
                 </div>
            )
        })
    }

    
    return (
        
        <>
        <SideNav/>
        <div className={classes['file-list']}>
            <div className={classes['search']}>

            <div className = {classes['search__icon']}>
            <img src='/Icons/search-solid-1.svg'/>
            </div>
            <input className={classes['search__bar']}placeholder='Search Files...' onChange={(event => setSearchTerm(event.target.value))}/>
            </div>
            <div className={classes['file-list__array']}>

            {fileArray}
            <div className={classes['shade']}/>
            </div>
            <div className={classes['add-button']} onClick={addFileHandler}>
            <IconButton aria-label="edit">
            <AddBoxIcon/>
            </IconButton> 
            Create New File</div>
        </div>

        <div className={classes['floating-button']} onClick={addFileHandler}>
            <img className={classes['floating-button__icon']} src='/Icons/plus-solid.svg'/>
        </div>

        </>
    )
}


export default FileSelection