import React, { useState } from 'react';
import classes from './Signup.module.css'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import SideNav from '../SideNav/SideNav';
export const Signup = (props) => {


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const { signup } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        if(password !== passwordConfirmation){
            return setError('Passwords do not match')
        }
        try{
            setError('')
            setLoading(true)
            await signup(email, password)
            history.push('/')
        } catch {
            setError('Failed to create an account')
        }
        setLoading(false)
    }
    return(
     
       <>
       <SideNav/>
        <div  className={classes['background']}>
            <div className={classes.signup}>
                <div className={classes['signup__title']}>
                    <h1>Accordinizer</h1>
                    <span><h1>Sign Up</h1></span>
                </div>
                <div className={error && `${classes['error']}`}>{error}</div>


                <form onSubmit={handleSubmit}>

                    <div className={classes['signup__email']}>
                        <EmailIcon/>
                        <label>Email:</label>
                        <input type='email' value={email} onChange={(e) =>{ setEmail(e.target.value)}}/>
                    </div>

                    <div className={classes['signup__password']}>
                        <LockIcon/>
                        <label>Password:</label>
                        <input type="password" value={password} onChange={(e) =>{ setPassword(e.target.value)}}/>
                    </div>

                    <div className={classes['signup__password-confirmation']}>
                        <LockIcon/>
                        <label>Password Confirmation:</label>
                        <input type="password" value={passwordConfirmation} onChange={(e) =>{ setPasswordConfirmation(e.target.value)}}/>
                    </div>

                    <div className={classes['signup__submit']}>
                        <button type='submit' disabled={loading}>Submit</button>
                    </div>
                </form>
                <div>
                Already have an Account? <Link to = "/login">Sign in</Link>
                </div>
            </div>
        </div>
    </>
    )
}
