import React, { useState } from 'react';
import classes from '../../components/Signup/Signup.module.css'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import SideNav from '../SideNav/SideNav';

export const UpdateProfile = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const { currentUser, updatePassword, updateEmail } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    function handleSubmit(e) {
        e.preventDefault()
        if(password !== passwordConfirmation){
            return setError('Passwords do not match')
        }
        const promises = []
        setLoading(true)
        setError('')
        if(email !== currentUser.email){
            promises.push(updateEmail(email))
        }
        if (password ){
            promises.push(updatePassword(password))
        }
        Promise.all(promises).then(() =>{
            history('/')
        }).catch(() => {
            setError('Failed to update account')
        }).finally(() => {
            setLoading(false)
        })      
    }
    return(

        <>
        <SideNav/>
        <div className={classes['background']}>
            <div className={classes.signup}>
                <div className={classes['signup__title']}>
                    <h1>Update Profile</h1>
                </div>

                <div className={error && `${classes['error']}`}>{error}</div>


                <form onSubmit={handleSubmit}>

                    <div className={classes['signup__email']}>
                        <EmailIcon/>
                        <label>Email:</label>
                        <input type='email' placeholder={currentUser.email} value={email} onChange={(e) =>{ setEmail(e.target.value)}}/>
                    </div>

                    <div className={classes['signup__password']}>
                        <LockIcon/>
                        <label>Password:</label>
                        <input type="password" value={password} onChange={(e) =>{ setPassword(e.target.value)}}/>
                    </div>

                    <div className={classes['signup__password-confirmation']}>
                        <LockIcon/>
                        <label>Password Confirmation:</label>
                        <input type="password" value={passwordConfirmation} onChange={(e) =>{ setPasswordConfirmation(e.target.value)}}/>
                    </div>

                    <div className={classes['signup__submit']}>
                        <button type='submit' disabled={loading}>Submit</button>
                    </div>
                </form>
                <Link to = '/'>Cancel</Link>
            </div>
        </div>
    </>
    )
}
