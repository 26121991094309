import React, { useContext, useState, useEffect } from 'react'
import { useAuth } from './AuthContext'
import axios from 'axios'
const  FileContext = React.createContext()
export function useFiles(){
    return useContext(FileContext)
} 

export function FileProvider({ children }){
    const [files, setFiles] = useState()
    const { currentUser, idToken } = useAuth()
    // console.log(files)
    function handleScroll() {
        window.scroll({
          top: document.body.offsetHeight,
          left: 0, 
          behavior: 'smooth',
        });
      }
    const addFileHandler = () =>{    
        let newFile = {fileName: 'untitled'}
        axios.post(`https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/.json?auth=${idToken}`, newFile )
        .then( response =>{
            console.log(response)
            console.log(files)
            let filesArray = [...files]
            filesArray.push([response.data.name, newFile])
            console.log([filesArray])
            setFiles(filesArray)
            handleScroll()
        })
        .catch(error => {
            console.log(error)
        })
    }

    const deleteFileHandler = (id) =>{
        axios.delete(`https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/${id}/.json?auth=${idToken}`)
        .then( response =>{
            console.log(response)
            const fileArray = [...files]
            const result = []
            for(let i = 0; i < fileArray.length; i++){
                if(fileArray[i][0] === id) continue;
                result.push(fileArray[i])
            }
            setFiles(result);
        }
        ).catch(error => console.log(error))
    }

    const renameFileHandler = (index, event) =>{
        console.log(event.target.value)
        const filesArray = [...files]
        filesArray[index][1].fileName = event.target.value;
        setFiles(filesArray)
    }
    const copyFileHandler = (fileToCopy, oldFileName) =>{
        
        // make it so that when you add a new file it increments the counter on the existing file by 1, like untitled(1) or untitled (2)

        axios.post(`https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/.json?auth=${idToken}`, fileToCopy[1])
        .then( response =>{
            const fileArray = [...files]
            fileArray.push([response.data.name, fileToCopy[1]])


            setFiles(fileArray);
        }
        )
        .catch(error => {
          alert('information failed to get sent to server. copy and paste info somewhere safe, then hit edit, and confirm once more')
          console.log(error)
        })
      }
    useEffect(() => {
        if(currentUser !== null){

                axios.get(`https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/.json?auth=${idToken}`)
                .then(response => {                   
                    // console.log(response)
                    // setFiles(Object.entries(response.data))

                    if(response.data ===null){
                        setFiles([])
                    }
                    else{
                        setFiles(Object.entries(response.data))
                    }
                } )
                .catch( error =>{
                    console.log(error)
                })
        }
    }, [currentUser, idToken])

    const value = {
        files,
        addFileHandler,
        renameFileHandler,
        deleteFileHandler,
        copyFileHandler
    }


    return(
        <FileContext.Provider value={value}>
            {children}
        </FileContext.Provider>
    )
}