import React, { useState } from 'react'

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from "react-router-dom";
import classes from '../FileName/FileName.module.css'
import {useAuth} from '../../contexts/AuthContext'


import axios from 'axios'
import { useFiles } from '../../contexts/FileContext';

const FileName = (props) => {

  const [editMode, setEditMode] = useState(false)
  const { currentUser } = useAuth()
  const { copyFileHandler } = useFiles()
  const [deleteMode, setDeleteMode] = useState(false)
  const editToggle=()=>{
    setEditMode(!editMode)
  }
  const confirmHandler = (fileName, id) => {
    setEditMode(!editMode)
    const newFileName = {fileName: fileName}
    axios.patch(`https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/${id}/.json?auth=${props.token}`, newFileName )
    .then(response => console.log(response))
    .catch(error => {
      alert('information failed to get sent to server. copy and paste info somewhere safe, then hit edit, and confirm once more')
      console.log(error)
    })
  }

  // const copyHandler = (file, id) =>{

  //   axios.post(`https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/${id}/.json?auth=${props.token}`)
  //   .then(response => console.log(response))
  //   .catch(error => {
  //     alert('information failed to get sent to server. copy and paste info somewhere safe, then hit edit, and confirm once more')
  //     console.log(error)
  //   })
  // }
    
  let finalFileName = (<Link className={classes['content__link']} to={`FileSelection/${props.id}`}>{[...props.fileName]}</Link>)
  let editButton = (
    <IconButton aria-label="edit" onClick = { editToggle}>
    <EditIcon />
    </IconButton>
    )

  let deleteChoice = (
  <IconButton aria-label="delete" onClick = {() =>  setDeleteMode(true)}>
  <DeleteForeverIcon className={classes['file-list__items--trash']}/>
  </IconButton>
  )
  if ( editMode === true){
    finalFileName = (
    <input  className={classes['rename-bar']} onChange = {props.rename}value={ props.fileName}/>
    )

    // switches pencil to check
    editButton = (<IconButton aria-label="confirm" 
    onClick = {() =>  confirmHandler( props.fileName, props.id)}>
    <DoneIcon />
    </IconButton>)
    // switches pencil to check
  }

  if( deleteMode === true){
    deleteChoice = (
      <>
      <IconButton aria-label="confirm" >
      <DoneIcon button onClick={() => {props.delete(props.id); setDeleteMode(!deleteMode)}}/>
      </IconButton>
      <IconButton aria-label="cancel" >
      <ClearIcon onClick={() => setDeleteMode(!deleteMode)}/>
      </IconButton>
      </>
    )
  }


    return(
    <div className={classes['single-entry']}>
      <div>
        <button onClick={() => copyFileHandler(props.fullFile, props.fileName)}>COPY FILE</button>
      </div>
      <div className={classes['content']}>
        {editButton}
        {finalFileName}
      </div>
      <div className={classes.trash}>
        {deleteChoice}
      </div>  
    </div>
  )
}

export default FileName