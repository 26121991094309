import React, { useState, useRef, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import classes from '../DemoAccordion/DemoAccordion.module.css';
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import Chevron from '../Chevron';

const AccordionEntry = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const [active, setActive] = useState('');
  const [height, setHeight] = useState(0);

  const [rotate, setRotate] = useState('accordion__icon');

  const content = useRef(null);

  function confirmHandler(title = 'title', info = 'info') {
    setEditMode(!editMode);
    // const information = {title: title, info: info }
  }

  function handleEditorChange(content, editor) {
    console.log('Content was updated:', content);
    props.infoHandler(props.index, content);
  }

  function toggleAccordion() {
    if (!editMode) {
      console.log(content.current.scrollHeight);
      setActive(active === '' ? 'active' : '');
      setHeight(active === 'active' ? 0 : content.current.scrollHeight);

      setRotate(
        active === 'active'
          ? classes['accordion__icon']
          : `${classes['accordion__icon']} ${classes['rotate']}`
      );
    }
  }
  let finalTitle = props.title;
  let info = parse(props.info);
  if (editMode === true) {
    finalTitle = <input onChange={props.titleHandler} value={props.title} />;
    info = (
      <Editor
        value={props.info}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'nonbreaking',
          ],
          nonbreaking_force_tab: true,
          fontsize_formats:
            '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
          toolbar:
            'undo redo | fontsizeselect | bold italic underline strikethrough | backcolor forecolor fontselect | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
        }}
        onEditorChange={handleEditorChange}
      />
    );
  }

  function cancelHandler() {
    setDeleteMode(!deleteMode);
  }
  function deleteHandler() {
    if (active !== 'active') {
      toggleAccordion();
    }
    setDeleteMode(!deleteMode);
  }
  let deleteChoice = (
    <IconButton aria-label="delete" onClick={deleteHandler}>
      <DeleteForeverIcon />
    </IconButton>
  );
  if (deleteMode === true) {
    deleteChoice = (
      <>
        <IconButton
          aria-label="confirm"
          onClick={() => {
            props.remove(props.index);
            setDeleteMode(!deleteMode);
          }}
        >
          <DoneIcon />
        </IconButton>

        <IconButton aria-label="cancel" onClick={cancelHandler}>
          <ClearIcon />
        </IconButton>
      </>
    );
  }

  function editHandler() {
    if (active !== 'active') {
      toggleAccordion();
    }
    setEditMode(!editMode);
  }
  let editChoice = (
    <IconButton aria-label="edit" onClick={editHandler}>
      <EditIcon />
    </IconButton>
  );
  if (editMode === true) {
    editChoice = (
      <IconButton
        aria-label="confirm"
        onClick={() => confirmHandler(props.title, props.info)}
      >
        <DoneIcon />
      </IconButton>
    );
  }

  useEffect(() => {
    if (editMode === true) {
      if (height < 500) setHeight(500);
    }
  }, [active, editMode, height]);

  return (
    <div className={classes['accordion']}>
      <div
        className={`${classes['accordion__header']} ${classes[active]}`}
        onClick={toggleAccordion}
      >
        <Chevron className={`${rotate}`} width={10} fill={'white'} />
        <div className={classes['accordion__header__edit']}>{editChoice} </div>
        <p className={classes['accordion__header__text']}>{finalTitle}</p>
        <div className={classes['accordion__header__delete']}>
          {deleteChoice}
        </div>
      </div>

      <div
        ref={content}
        id={`${props.id}`}
        style={{ maxHeight: `${height}px` }}
        className={classes['accordion__content']}
      >
        {info}
      </div>
    </div>
  );
};

export default AccordionEntry;
