import React, { useState, useEffect } from 'react';
import AccordEntry from '../AccordEntry/AccordEntry';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import classes from './AccordFile.module.css';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import { useFiles } from '../../contexts/FileContext';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SideNav from '../SideNav/SideNav';

const AccordionEntries = (props) => {
  const [accordFetch, setAccordFetch] = useState([]);
  const [fileName, setFileName] = useState('');
  const { currentUser, idToken } = useAuth();
  const [fileNameToggle, setFileNameToggle] = useState(false);
  const { files } = useFiles();

  useEffect(() => {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i][0] === props.match.params.id) {
          setAccordFetch(Object.entries(files[i][1]));
          setFileName(files[i][1].fileName);
          break;
        }
      }
    }
  }, [files]);

  let isEmpty = '';
  if (accordFetch.length <= 1) {
    isEmpty = <div className={classes['isEmpty']}>Add Your First Entry!</div>;
  }
  const addEntryHandler = () => {
    const information = { title: 'untitled', info: 'Add a description!' };
    axios
      .post(
        `https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/${props.match.params.id}/.json?auth=${idToken}`,
        information
      )
      .then((response) => {
        console.log(response);
        console.log(response.data.name);
        console.log(accordFetch);
        const accordFetchCopy = [...accordFetch];
        accordFetchCopy.splice(accordFetchCopy.length - 1, 0, [
          response.data.name,
          information,
        ]);
        setAccordFetch(accordFetchCopy);
      })
      .catch((error) => {
        alert('Add entry failed, refresh page and attempt again');

        console.log(error);
      });
  };

  const removeEntryHandler = (id) => {
    const accordFetchCopy = [...accordFetch];
    let result = [];
    for (let i = 0; i < accordFetchCopy.length; i++) {
      if (accordFetchCopy[i][0] === id) continue;
      result.push(accordFetchCopy[i]);
    }
    setAccordFetch(result);
    axios
      .delete(
        `https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/${props.match.params.id}/${id}.json?auth=${idToken}`
      )
      .then((response) => console.log(response))
      .catch((error) => {
        alert('Removal failed, refresh page and attempt removal again');
        console.log(error);
      });
  };

  const titleHandler = (index, event) => {
    const accordListT = [...accordFetch];
    accordListT[index][1].title = event.target.value;
    setAccordFetch(accordListT);
  };
  const infoHandler = (index, content) => {
    const accordListT = [...accordFetch];
    accordListT[index][1].info = content;
    setAccordFetch(accordListT);
  };

  const fileNameHandler = () => {
    const accordFetchCopy = [...accordFetch];
    accordFetchCopy[accordFetchCopy.length - 1][1] = fileName;
    setAccordFetch(accordFetchCopy);
    let payload = { fileName };
    axios
      .patch(
        `https://accordion-production-default-rtdb.firebaseio.com/users/${currentUser.uid}/${props.match.params.id}.json?auth=${idToken}`,
        payload
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));

    setFileNameToggle(!fileNameToggle);
  };

  const accordFetchArray = accordFetch.map((value, index, arr) => {
    // console.log(arr);
    if (value[0] === 'fileName') return;
    return (
      <AccordEntry
        key={value[0]}
        title={value[1].title}
        info={value[1].info}
        fileId={value[0]}
        editMode={value[1].editMode}
        titleHandler={(event) => titleHandler(index, event)}
        infoHandler={infoHandler}
        remove={removeEntryHandler}
        fileName={props.match.params.id}
        token={idToken}
        index={index}
        eventKey={index}
      />
    );
  });

  let fileNameTag = fileName;

  let editButton = (
    <IconButton
      aria-label="edit"
      onClick={() => setFileNameToggle(!fileNameToggle)}
    >
      <EditIcon />
    </IconButton>
  );
  if (fileNameToggle) {
    editButton = (
      <IconButton aria-label="confirm">
        <DoneIcon onClick={fileNameHandler} />
      </IconButton>
    );

    fileNameTag = (
      <input onChange={(e) => setFileName(e.target.value)} value={fileName} />
    );
  }
  return (
    <>
      <div className={classes['background']}>
        <SideNav />
        <div className={classes.container}>
          <IconButton color="white" className={classes['back']} href="/">
            <ArrowBackIcon /> Back
          </IconButton>
          <br />

          <div className={classes['file-name']}>
            {editButton}
            {fileNameTag}
          </div>
          {accordFetchArray}
          <button className={classes['add-entry']} onClick={addEntryHandler}>
            <AddBoxIcon />
            <span className={classes['add-entry__text']}>Add entry</span>
          </button>

          {isEmpty}
          <div className={classes['floating-button']} onClick={addEntryHandler}>
            <img
              className={classes['floating-button__icon']}
              src="/Icons/plus-solid.svg"
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default AccordionEntries;
