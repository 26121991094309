import React, { useState } from 'react';
import classes from '../../components/Signup/Signup.module.css'
import { useAuth } from '../../contexts/AuthContext'
import { Link } from 'react-router-dom'
export const ForgotPassword = (props) => {

    const [email, setEmail] = useState('')
    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    async function handleSubmit(e) {
        e.preventDefault()

        try{
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(email)
            setMessage('Check your inbox for further instructions')
        } catch {
            setError('Failed to reset password')
        }
        setLoading(false)
    }
    return(
    <>
    <div className={classes.signup}>
        <h1>Password Reset</h1>
        <div>{message}</div>
        {message && console.log(message)}

        <div className={classes['signup__email']}>
        <label>Email:</label>
        <input type='email' value={email} onChange={(e) =>{ setEmail(e.target.value)}}/>
        </div>
        <div className={classes['signup__submit']}>

        <button onClick={handleSubmit} disabled={loading}>Reset Password</button>
        </div>
        <div>
            Need an account? <Link to ='/signup'>Sign up</Link>
        </div>
        <div>
            <Link to = "/login">Login Instead</Link>
        </div>
    </div>
    </>
    )
}
