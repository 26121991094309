import React from 'react'
import TopNav from '../TopNav/TopNav'
import classes from './Contact.module.css'
import { useAuth } from '../../contexts/AuthContext'
import SideNav from '../SideNav/SideNav'
import PreSideNav from '../SideNav/PreSideNav'
import useViewport from '../../useViewport'

export default function Contact() {

    const { currentUser } = useAuth()
    const { width } = useViewport();
    const breakpoint = 1000;
    
    let noAuth = (width < breakpoint ? <PreSideNav/> : <TopNav/>)
    
    return (


        <>
        <div className={classes['container']}>
            {currentUser ? <SideNav/> : noAuth}
            <div className={classes['contact']}>
            <h1>CONTACT</h1>
            <p><strong>Email:</strong> shayneaawilliams@gmail.com</p>
            <a href=' https://www.linkedin.com/in/shayne-williams-b2855b155'><strong>Linkedin:</strong> https://www.linkedin.com/in/shayne-williams-b2855b155</a>
            <p>If you have any bugs you'd like to report, or features you'd like to request, feel free to contact me.</p>
            </div>

        </div>
        </>
    )
}
